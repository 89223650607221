import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { auth } from "../../database/firebase";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import dayjs from "dayjs";
import {
  Button,
  Cascader,
  TimePicker,
  Form,
  Popconfirm,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Divider,
  Switch,
} from "antd";
import { useSelector } from "react-redux";
import { openErrorNotification } from "../../Components/Notifications/errorNotification";
import { deleteDataDoc, getDataDoc, updateDocDatabase } from "../../database";
import { initializeApp } from "firebase/app";
import { testSlice } from "../../redux/store";
import { useNavigate } from "react-router-dom";
const { actions } = testSlice;
const { GET_CLASE } = actions;
const { TextArea } = Input;
function ModalSettingsClasa({
  setOpen,
  open,
  confirmLoading,
  setConfirmLoading,
  clasa,
  setClassData,
}) {
  const [startHour, setStartHour] = useState(15);
  const [endHour, setEndHour] = useState(20);

  const [diriginte, setDiriginte] = useState("");
  const profesori = useSelector((state) => state.profesori);
  const materiiRedux = useSelector((state) => state.materii);
  const [materii, setMaterii] = useState([]);
  const clase = useSelector((state) => state.clase);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(materiiRedux);
  const handleOk = async () => {
    try {
      setConfirmLoading(true);

      await updateDocDatabase("claseData", clasa?.id, {
        settings: {
          ...clasa.settings,
          startHour,
          endHour,
        },
        diriginte,
        materii,
      });
      setConfirmLoading(false);
      setOpen(false);

      let ClasaArray = clase.find((cls) => cls?.id === clasa?.id);

      await updateDocDatabase("clase", "clase", {
        clase: [
          ...clase.filter((cls) => cls?.id !== clasa?.id),
          { ...ClasaArray, diriginte },
        ],
      });
    } catch {}
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    setStartHour(clasa?.settings?.startHour || "08");
    setEndHour(clasa?.settings?.endHour || "15");
    setDiriginte(clasa?.diriginte);
    setMaterii(clasa?.materii || []);
  }, [clasa]);

  const confirm = async () => {
    try {
      dispatch(GET_CLASE(clase.filter((cls) => cls.id !== clasa.id)));
      let dataClass = await getDataDoc("claseData", clasa.id);
      for await (let ora of dataClass.ore || []) {
        let profDoc = await getDataDoc("profesori", ora.profId);
        await updateDocDatabase("profesori", ora.profId, {
          ore: (profDoc.ore || []).filter((o) => o.classId !== clasa.id),
        });
      }
      updateDocDatabase("clase", "clase", {
        clase: clase.filter((cls) => cls.id !== clasa.id),
      });
      for await (let elev of clasa.elevi || []) {
        await updateDocDatabase("elevi", elev.id, {
          clasa: "faraclasa",
        });
      }
      let dataOfNone = await getDataDoc("claseData", "faraclasa");
      await updateDocDatabase("claseData", "faraclasa", {
        elevi: [...dataOfNone.elevi, ...clasa.elevi],
      });

      deleteDataDoc("claseData", clasa.id);
      navigate("/clase");
    } catch {}
  };

  const format = "HH";

  return (
    <Modal
      title="Setări"
      open={open}
      onOk={handleOk}
      width={1000}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <div style={{ display: "block", alignItems: "center", margin: "0" }}>
        Ora Start:{"   "}
        <TimePicker
          value={dayjs(startHour || "15", format)}
          onChange={(e, value) => {
            setStartHour(value);
          }}
          placeholder="Orar Start"
          format="HH"
        />
        <br />
        <br />
        Ora Final:{" "}
        <TimePicker
          value={dayjs(endHour || "20", format)}
          onChange={(e, value) => {
            setEndHour(value);
          }}
          placeholder="Orar Sfarsit"
          picker="hour"
          style={{ textAlign: "center" }}
          format="HH"
        />
        <br />
        <br />
        <Form.Item label="Dir./Inv.">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Diriginte/Invatator:"
            optionFilterProp="children"
            value={diriginte}
            onChange={(value) => {
              setDiriginte(value);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={profesori.map((prof) => {
              return {
                label: prof?.numeDeFamilie + " " + prof?.prenume,
                value: prof?.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item label="Materii">
          {materii.map((materie, index) => {
            return (
              <>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Select
                    showSearch
                    placeholder="Materie"
                    optionFilterProp="children"
                    value={materie.materie}
                    onChange={(value) => {
                      let subjects = JSON.parse(JSON.stringify(materii));
                      subjects[index].materie = value;
                      setMaterii(subjects);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={materiiRedux.map((mat) => {
                      return {
                        label: mat?.numeMaterie + "-" + mat?.profil,
                        value: mat?.id,
                      };
                    })}
                  />
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Profesor"
                    optionFilterProp="children"
                    value={materie.profesori}
                    onChange={(value) => {
                      let subjects = JSON.parse(JSON.stringify(materii));
                      subjects[index].profesori = value;
                      setMaterii(subjects);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={(
                      materiiRedux.find((mat) => mat.id === materie?.materie)
                        ?.profesori || []
                    ).map((profID) => {
                      let prof = profesori.find((pf) => pf.id === profID);
                      return {
                        label: prof?.numeDeFamilie + "-" + prof?.prenume,
                        value: prof?.id,
                      };
                    })}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => {
                      setMaterii(
                        materii.filter((ma) => ma.materie !== materie?.materie)
                      );
                    }}
                  />
                </div>
                <br />
              </>
            );
          })}
          <Button
            onClick={() => {
              setMaterii([...materii, { materie: "" }]);
            }}
          >
            <PlusOutlined />
          </Button>
        </Form.Item>
        <br />
        <br />
        <Popconfirm
          title="Sterge Clasa"
          description="Esti sigur ca vrei sa stergi clasa?"
          onConfirm={confirm}
          okText="Da"
          cancelText="Nu"
        >
          <Button danger>Sterge Clasa</Button>
        </Popconfirm>
      </div>
    </Modal>
  );
}

export default ModalSettingsClasa;
